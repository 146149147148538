




import {Component, Vue} from 'vue-property-decorator';
import Export from '@/components/Export.vue';

@Component({
  components: {
    Export,
  },
})
export default class LearningFeatureExport extends Vue {
}
